<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        v-if="icon"
        :class="cn('[&_svg]:size-full [&_svg_*]:[vector-effect:non-scaling-stroke]', iconClass, props.class)"
        v-html="icon"></div>
</template>
<script setup lang="ts">
import type { HTMLAttributes } from "vue";

const props = defineProps<{ category: Category; size: string; class?: HTMLAttributes["class"] }>();

// Auto-load icons
const icons = Object.fromEntries(
    Object.entries(import.meta.glob("~/assets/part-category/*.svg", { query: "?raw", import: "default" })).map(
        ([key, value]) => {
            const filename = key.split("/").pop()?.split(".").shift();
            return [filename, value];
        }
    )
);

// Lazily load the icon
const icon = props.category.ImageUrl && (await icons?.[props.category.ImageUrl]?.());

const iconClass = computed(() => {
    if (props.size === "sm") return "size-8  [&_svg_*]:stroke-1";
    if (props.size === "lg") return "size-20 [&_svg_*]:stroke-2";
    return "size-16 [&_svg_*]:stroke-[1.5]";
});
</script>
